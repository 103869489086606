import React from 'react';
import * as styles from './Card.module.css';
import clsx from 'clsx';

export default function Card({ className, content, children }) {
  return (
    <div className={clsx(styles.cardWrapper, className )}>
      <div className={ clsx(styles.card)}>
        <div className={styles.icon}>
          <content.icon />
        </div>
        <div className={styles.title}>
          { content.title}
        </div>
      </div>
      { children }
    </div>
  );
}